<template>
  <div class="devcomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="选择厂商">
              <el-select v-model="vsvalue" placeholder="请选择">
                <el-option
                    v-for="item in vselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="选择用户">
              <el-select v-model="usvalue" placeholder="请选择">
                <el-option
                    v-for="item in uselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择状态">
              <el-select v-model="ssvalue" placeholder="请选择">
                <el-option
                    v-for="item in sselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="  ">
              <el-input placeholder="输入设备名或SN" v-model="searchinput">
                <template slot="prepend">查找设备:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>

          </el-form>

        </div>
        <div class="search">


        </div>

        <el-table
            :data="tbdata"
            border
            stripe
            height="660"
            style="width: 100%; margin: 3px"
        >
          <el-table-column
              prop="sn"
              align="center"
              label="SN"
              width="145"
          >
          </el-table-column>
          <el-table-column prop="name"   label="设备名称" align="center">
          </el-table-column>

          <el-table-column prop="model" width="80"  label="型号" align="center">
          </el-table-column>

          <el-table-column prop="status" width="80"  label="状态" align="center">
          </el-table-column>

          <el-table-column prop="counts" width="105"  label="剩余次数" align="center">
          </el-table-column>

          <el-table-column prop="today" width="105"  label="今日次数" align="center">
          </el-table-column>

          <el-table-column prop="total" width="105"  label="总次数" align="center">
          </el-table-column>

          <el-table-column width="670">
            <template slot="header">
              <el-button type="primary" icon="el-icon-refresh" style="margin-left: 30px;width: 160px"
                         @click="onRefresh">刷新列表
              </el-button>
              <el-button type="primary" icon="el-icon-download" style="margin-left: 30px;width: 160px" plain
                         @click="ExportDeviceExcel">导出设备列表
              </el-button>
            </template>
            <template slot-scope="scope">
              <el-button size="mini" type="success" plain @click="DeviceTrun(scope.$index,'1')">启动设备</el-button>
              <el-button size="mini" type="danger" plain @click="DeviceTrun(scope.$index,'0')">停止设备</el-button>

              <span v-if="info.identity >0">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" @click="OpenRechargeDialog(scope.$index)" type="primary"
                           plain>次数充值</el-button>
                <el-button size="mini" @click="OpenModifyDialog(scope.$index)">设备命名</el-button>
              </span>
              
              

              <span v-if="info.identity >1" >
                <el-divider direction="vertical"></el-divider>
                <span v-if="enen">
                <el-button size="mini" @click="EnableDisableDevice(scope.$index,1)" type="success"
                           plain>设备启用</el-button>
                </span>
                <span v-if="enen==false">
                <el-button size="mini" @click="EnableDisableDevice(scope.$index,0)" type="info"
                           plain>设备禁用</el-button>
                </span>
                <el-button style="margin-left: 5px" size="mini" @click="OpenAllocateDialog(scope.$index,0)" type="primary"
                           plain>设备分配</el-button>
              </span>
              

              <span v-if="info.identity >= 1">
                <el-divider direction="vertical"></el-divider>
                <el-button @click="ExportExcelDialog(scope.$index)" size="mini" type="primary">导出报表</el-button>
              </span>

              
              <span v-if="info.identity >2">
                <el-divider direction="vertical"></el-divider>
                <el-button @click="DeleteDeviceCommit(scope.$index)" size="mini" type="danger">设备删除</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <!--      .........................-->
      <!--      <el-col :span="5">-->
      <!--        <el-card class="detail-card">-->
      <!--          <ul id="main_info" style="line-height: 35px">-->
      <!--            <li>SN：{{detail.sn}}</li>-->
      <!--            <li>设备名称：{{detail.name}}</li>-->
      <!--            <li>设备型号：{{detail.model}}</li>-->
      <!--            <li>设备状态：{{detail.status}}</li>-->
      <!--            <li>剩余次数：{{detail.counts}}次</li>-->
      <!--            <li>今日次数：{{detail.today}}次</li>-->
      <!--            <li>本月次数：{{detail.month}}次</li>-->
      <!--            <li>总次数：{{detail.total}}次</li>-->
      <!--            <li>加热温度：{{detail.heat_temp}}℃</li>-->
      <!--            <li>设备温度：{{detail.device_temp}}℃</li>-->
      <!--            <li>更新时间：{{detail.update_time}}</li>-->
      <!--          </ul>-->
      <!--        </el-card>-->
      <!--      </el-col>-->


    </el-row>
    <!-- 修改床名字 Start -->
    <el-dialog title="修改名称" :visible.sync="modifyDeviceNameDialog" width="500px">
                <el-form>
                  <el-form-item label="命名" label-width="80px">
                    <el-input clearable v-model="modifyEditor" autocomplete="off" maxlength="16"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="modifyDeviceNameDialog = false">取 消</el-button>
                  <el-button type="primary" @click="ModifyNameCommit">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 修改床名字 END -->

              <!-- 充值次数 Start -->
              <el-dialog title="设备次数充值" :visible.sync="RechargeTimesDialog" width="400px">
                <el-form>
                  <el-form-item label="充值次数：" label-width="100px">
                    <el-input type="number" v-model="RechargeEditor" autocomplete="off"></el-input>
                  </el-form-item>
                </el-form>
                <p style="margin-left: 16px"><i>当前剩余可分配次数:</i> <b style="margin-left: 4px">{{
                    info.atimes
                  }}</b></p>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="RechargeTimesDialog = false">取 消</el-button>
                  <el-button type="success" @click="RechargeCommit">充 次</el-button>
                </div>
              </el-dialog>
              <!-- 充值次数 END -->
              <!-- 设备分配 Start -->
              <el-dialog title="设备分配" :visible.sync="DeviceAllocateDialog" width="500px">
                <el-form>
                  <el-form-item label="选择设备所属用户" label-width="160px">
                    <el-select v-model="AllocValue" placeholder="请选择">
                      <el-option
                          v-for="item in AllocList"
                          :key="item.uid"
                          :label="item.name"
                          :value="item.uid">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="DeviceAllocateDialog = false">取 消</el-button>
                  <el-button type="primary" @click="AllocateCommit">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 设备分配 END -->

              <!-- 导出报表 Start -->
              <el-dialog title="导出报表" :visible.sync="ExcelDialog" width="500px" >

<div class="block">
  <span style="margin-right: 5px">选择导出时间：</span>
  <el-date-picker
      v-model="excelTime"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions">
  </el-date-picker>
</div>
<div slot="footer" class="dialog-footer">
  <el-button @click="ExcelDialog = false">取 消</el-button>
  <el-button type="primary" @click="ExcelCommit">导 出</el-button>
</div>
</el-dialog>
<!-- 导出报表 END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchinput: "",
      tbdata: [
        {
          "sn": "",
          "name": "",
          "model": "",
          "status": "",
          "counts": 0,
          "today": 0,
          "total": 0
        },],
      vselect: [{
        value: '',
        label: '不限'
      }],
      vsvalue: '',
      uselect: [{
        value: '',
        label: '不限'
      }],
      usvalue: '',
      sselect: [{
        value: '',
        label: '不限'
      }, {
        value: '在线',
        label: '在线'
      }, {
        value: '运行',
        label: '运行'
      }, {
        value: '离线',
        label: '离线'
      }, {
        value: '禁用',
        label: '禁用'
      }],
      ssvalue: '',
      // detail:{
      //   "sn": "",
      //   "name": "",
      //   "model": "",
      //   "status": "",
      //   "counts": 0,
      //   "today": 0,
      //   "month": 0,
      //   "total": 0,
      //   "heat_temp": 0,
      //   "device_temp": 0,
      //   "update_time": ""
      // }

      // 充次
      RechargeTimesDialog: false,
      RechargeEditor: "20",
      RechargeSN: "",
      // 修改名字
      modifyDeviceNameDialog: false,
      modifyEditor: "",
      modifySN: "",
      // 设备分配
      DeviceAllocateDialog: false,
      AllocSN: "",
      AllocValue: "",
      AllocList: null,

      // 导出报表
      ExcelDialog: false,
      excelTime: "",
      ExcelSN: "",
      ExcelName: "",
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      orderHeader: {
        i: '序号',
        c: '剩余次数',
        s: '设备启动时间',
        e: '设备停止时间',
        u: '工作时长',
        m: '备注',
      },
      orderList: [],
      //device list excel
      deviceHeader:
          {
            sn: 'SN',
            name: '设备名称',
            model: '型号',
            status: '状态',
            counts: '剩余次数',
            today: '今日次数',
            month: '每月次数',
            total: '总次数',
            updateTime: '更新时间',
          },
      enen:false,
    }
  },
  created() {
    this.info = this.$store.state.userInfo

    this.onGetVUList();
    this.onRefresh();
  },
  mounted() {

  },
  beforeDestroy() {
    //this.closeTimer()
  },
  methods: {
    // 启动设备
    async DeviceTrun(index, turn) {
      let res = await this.$api.turn_device({
        sn: this.tbdata[index].sn,
        turn: turn,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '操作成功',
          type: 'success',
        })
      }
    },
    // 导出报表
    ExportExcelDialog(index) {
      this.ExcelSN = this.tbdata[index].sn
      this.ExcelName = this.tbdata[index].name
      this.ExcelDialog = true
    },
    async ExcelCommit() {
      let res = await this.$api.deviceorder_export({
        sn: this.ExcelSN,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.orderList = res.data

        this.ExportOrderExcel()
      }

    },
    async ExportOrderExcel() {
      //////////////++++++++++++++////////// order excel //++++++++++++++++++++++//////////////////
      console.log('ExportOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备报表-" + this.ExcelName + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.orderList))
      let map = []
      let cols = Object.keys(this.orderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.orderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.orderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 110
      }
      widthMap['A'] = 60
      widthMap['B'] = 70
      widthMap['F'] = 90

      let colConf = this.$Excel.makeColConfig({...widthMap}, 110)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'dcdcdc'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------
    // 启用禁用设备
    async EnableDisableDevice(index, en) {
      let res = await this.$api.enable_device({
        sn: this.tbdata[index].sn,
        en: en,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '操作成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    // 充次弹窗
    OpenRechargeDialog(index) {
      this.RechargeSN = this.tbdata[index].sn
      this.RechargeTimesDialog = true
    },
    ExportDeviceExcel() {
      /////////////////////////////////////////// excel //////////////////////////////////////////////////
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name =
          this.info.name +
          '-设备列表.xlsx' // TODO 加时间
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.deviceHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.deviceHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.deviceHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 70
      }
      widthMap['A'] = 100
      widthMap['B'] = 60
      widthMap['H'] = 120

      let colConf = this.$Excel.makeColConfig({...widthMap}, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'dcdcdc'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
    // 充次
    async RechargeCommit() {
      let res = await this.$api.charge_device({
        sn: this.RechargeSN,
        times: this.RechargeEditor.toString(),
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '充次已发送',
          type: 'success',
        })
        this.onRefresh();
        if (this.info.atimes != -1 && res.atimes != -2) {
          this.info.atimes = res.atimes
          this.$store.dispatch('Login', this.info)
        }
      }
      this.RechargeTimesDialog = false
    },
    // 修改名字
    OpenModifyDialog(index) {
      this.modifyEditor = this.tbdata[index].name
      this.modifySN = this.tbdata[index].sn
      this.modifyDeviceNameDialog = true
    },
    async ModifyNameCommit() {
      let res = await this.$api.rename_device({
        sn: this.modifySN,
        rename: this.modifyEditor,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyDeviceNameDialog = false
    },
    // 设备分配

    async GetKids() {
      let res = await this.$api.sel_kids()
      if (res.status == 1) {
        this.AllocList = res.data
      }
    },
    OpenAllocateDialog(index) {
      this.AllocSN = this.tbdata[index].sn
      this.DeviceAllocateDialog = true
      if (this.AllocList == null) {
        this.GetKids()
      }
      this.DeviceAllocateDialog = true
    },
    async AllocateCommit() {
      let res = await this.$api.bind_or_not({
        sn: this.AllocSN,
        uid: this.AllocValue,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.DeviceAllocateDialog = false
    },
    // handleMore(index) {
    //   this.index = index
    //   this.onGetDeviceDetail()
    // },
    async onGetVUList() {
      let res = await this.$api.vu_list()
      console.log(res)
      if (res.status == 1) {
        if (res.u != null)
          this.uselect = [{
            value: '',
            label: '不限'
          }, {
            value: '0',
            label: '未分配'
          }, ...res.u]
        if (res.v != null)
          this.vselect = [{
            value: '',
            label: '不限'
          }, ...res.v]
      } else {
        this.uselect = [{
          value: '',
          label: '不限'
        }, {
          value: '0',
          label: '未分配'
        }]
        this.vselect = [{
          value: '',
          label: '不限'
        }]
      }
    },
    //////
    // 主刷新
    async onRefresh() {
      let res = await this.$api.device_list({
        name: this.searchinput,
        vid: this.vsvalue,
        uid: this.usvalue,
        status: this.ssvalue
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
        if (this.ssvalue == "禁用"){
          this.enen = true
        }else {
          this.enen = false
        }
      }
    },
    async DeleteDeviceCommit(index) {
      let res = await this.$api.delete_device({
        sn: this.tbdata[index].sn
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    async onGetDeviceDetail() {
      let res = await this.$api.device_detail({
        sn: this.tbdata[this.index].sn
      })
      console.log(res)
      if (res.status == 1) {
        this.detail = res.data

      }

    },
  },
}
</script>

<style scoped>

.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-card {
  margin-top: 70px;
  height: 710px;
  margin-left: 12px;

}

.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}

</style>
